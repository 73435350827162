import React from 'react';
import AppButton from './AppButton';
import googlePlayBadge from '../../../assets/images/google-play-badge.svg';
import presets from '../../../styles/presets';

interface Props {
  color?: string;
  href: string;
}

const GooglePlayStoreButton: React.FC<Props> = ({ color, href }) => {
  return (
    <AppButton
      css={color && { backgroundColor: color }}
      href={href}
      rel="noopener"
      target="_blank"
    >
      <img
        src={googlePlayBadge}
        alt="Get it on Google Play"
        css={{
          width: 121,
          margin: 0,
          [presets.md]: {
            width: 159,
          },
        }}
      />
    </AppButton>
  );
};

export default GooglePlayStoreButton;
