import styled from '@emotion/styled';
import presets from '../../../styles/presets';

const AppButton = styled('a')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 140,
  height: 46,
  backgroundColor: '#489F9D',
  borderRadius: 3,
  margin: '0 16px 12px 16px',
  [presets.md]: {
    width: 180,
    height: 60,
  },
});

export default AppButton;
