import React from 'react';
import presets from '../../../styles/presets';
import appStoreBadge from '../../../assets/images/app-store-badge.svg';
import AppButton from './AppButton';

interface Props {
  color?: string;
  href: string;
}

const AppStoreButton: React.FC<Props> = ({ color, href }) => {
  return (
    <AppButton
      css={color && { backgroundColor: color }}
      href={href}
      rel="noopener"
      target="_blank"
    >
      <img
        src={appStoreBadge}
        alt="Download on the App Store"
        css={{
          width: 117,
          margin: 0,
          [presets.md]: {
            width: 152,
          },
        }}
      />
    </AppButton>
  );
};

export default AppStoreButton;
